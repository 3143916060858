import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import * as auth from "../_redux/authRedux";
import * as authCrud from "../_redux/authCrud";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/
function Login(props) {
  const [businessList, setBusinessList] = useState();
  const [token, setToken] = useState();
  const [selected, setSelected] = useState();
  const dispatch = useDispatch();
  let { status, auth_token, refresh_token, user } = useParams();

  // const { intl } = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    username: Yup.string().required("Required Field"),
    password: Yup.string().required("Required Field")
  });
  const formik = useFormik({
    initialValues: { username: "", password: "" },
    validationSchema: LoginSchema,
    onSubmit: values => {
      login_with_username_and_password(values);
    }
  });
  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const loadToState = (authToken, refreshToken, user) => {
    dispatch(auth.actions.login({ authToken, refreshToken, user }));
  };

  useEffect(() => {
    if (status === "1" && auth_token && refresh_token && user) {
      loadToState(
        decodeURIComponent(auth_token),
        decodeURIComponent(refresh_token),
        JSON.parse(decodeURIComponent(user))
      );
    }
    // eslint-disable-next-line
  }, [status, auth_token, refresh_token, user]);

  const login_with_username_and_password = async auth_data => {
    try {
      setLoading(true);
      const response = await authCrud.login_with_username_and_password(
        auth_data
      );
      if (response.data.status) {
        setLoading(false);
        setBusinessList(response.data.data.business_list);
        setToken(response.data.data.token);
      } else {
        setLoading(false);
        formik.setStatus("Incorrect username or password.");
      }
    } catch (error) {
      setLoading(false);
      formik.setStatus("Unable to login!!! Try again");
    }
  };

  const login_with_user_module = async (tokentemp, module_id) => {
    try {
      setLoading(true);
      const response = await authCrud.login_with_user_module({
        token: tokentemp,
        module_id: module_id
      });
      setLoading(false);
      if (response.data.status) {
        //we have token in this response
        let authToken = response.data.data.auth_token;
        let refreshToken = response.data.data.refresh_token;
        let user = response.data.data.user;

        //save this token
        loadToState(authToken, refreshToken, user);
      } else {
        toast.warn("Unable to login!!! Try again");
      }
    } catch (error) {
      setLoading(false);
      toast.warn("Unable to login!!! Try again");
    }
  };

  useEffect(() => {
    window.addEventListener(
      "message",
      async event => {
        let url = process.env.REACT_APP_USER_MODULE_URL;
        url = url.split("/");

        var protocol = url[0];
        var host = url[2];
        var origin = protocol + "//" + host;
        if (event.origin === origin) {
          //window.location.href = "<%= redirectURL %>/moduleauthenticated?token=" + event.data.token + '&module_id=' + event.data.module_id

          //we need to send this token to API, and check that is this a new user or a previous user
          //if this is a new user then we need to ask them for their role i.e. Teacher or student
          login_with_user_module(event.data.token, event.data.module_id);
        }
        return;
      },
      false
    );
    return;
    // eslint-disable-next-line
  }, []);

  const businessSelected = async business_id => {
    try {
      setLoading(true);
      setSelected(business_id);
      const response = await authCrud.login_to_business_with_token({
        token,
        business_id
      });
      setLoading(false);
      if (response.data.status) {
        const res = response.data.data;
        //we have token in this response
        let authToken = res.auth_token;
        let refreshToken = res.refresh_token;
        let user = res.user;

        // //save this token
        loadToState(authToken, refreshToken, user);
      } else {
        setLoading(false);
        toast.error("You do not have access to this module, contact an Admin");
      }
    } catch (error) {
      setLoading(false);
      toast.error("You do not have access to this module, contact an Admin");
    }
  };

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {businessList ? (
        <>
          {/*begin::Header*/}
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                Please Select a Business
              </span>
              {/* <span className='text-muted mt-3 font-weight-bold font-size-sm'>
                          Pending 10 tasks
                        </span> */}
            </h3>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className="card-body pt-1">
            {businessList.map((business, key) => (
              <div
                onClick={() => businessSelected(business.id)}
                key={key}
                className="d-flex align-items-center mb-3 bg-primary-o-20 bg-hover-state-primary rounded-sm p-3 text-dark text-hover-light"
              >
                {/*begin::Symbol*/}
                <div className="symbol symbol-40 symbol-light-primary mr-5">
                  <span className="symbol-label btn btn-icon btn-light">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      {selected === business.id && loading ? (
                        <span className="spinner-border spinner-border-sm align-middle"></span>
                      ) : (
                        <span className="material-icons">business</span>
                      )}
                    </span>
                  </span>
                </div>
                {/*end::Symbol*/}
                {/*begin::Text*/}
                <div className="d-flex flex-column font-weight-bold">
                  <span
                    // href='#'
                    className="font-size-lg text-capitalize"
                  >
                    {business.name}
                  </span>
                  {/* <span className='text-muted'>Project Manager</span> */}
                </div>
                {/*end::Text*/}
              </div>
            ))}
          </div>
          {/*end::Body*/}
        </>
      ) : (
        <>
          {/* begin::Head */}
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>
            <p className="text-muted font-weight-bold">
              Enter your login details
            </p>
          </div>
          {/* end::Head */}

          <div className="text-center mb-5">
            <button
              className="btn btn-primary btn-block"
              type="button"
              onClick={() => {
                setLoading(true);
                window.open(
                  process.env.REACT_APP_USER_MODULE_URL,
                  "test",
                  "width=500,height=500"
                );
              }}
              disabled={loading}
            >
              {loading === "login_with_user_module" ? (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Sign in with SingleLogin.io"
              )}
            </button>
          </div>
          {/*begin::Form*/}
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Username"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "username"
                )}`}
                name="username"
                {...formik.getFieldProps("username")}
              />
              {formik.touched.username && formik.errors.username && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.username}</div>
                </div>
              )}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Password"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "password"
                )}`}
                name="password"
                {...formik.getFieldProps("password")}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
              <div className="text-dark-50 text-hover-primary my-3 mr-2"></div>
              <button
                id="kt_login_signin_submit"
                type="submit"
                disabled={loading}
                className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
              >
                <span>Sign In</span>
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
            </div>
          </form>
          {/*end::Form*/}
        </>
      )}
    </div>
  );
}

export default connect(null, auth.actions)(Login);
