import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { boardsSlice } from "../app/modules/Application/_redux/boards/boardsSlice";
import { teamsSlice } from "../app/modules/Application/_redux/teams/teamsSlice";
import { invoicesSlice } from "../app/modules/Application/pages/Invoice/_redux/invoices/invoicesSlice";
import { teammembersSlice } from "../app/modules/Application/_redux/teammembers/teammembersSlice";
import { kanbanSlice } from "../app/modules/Application/_redux/kanban/kanbanSlice";
import { dashboardSlice } from "../app/modules/Application/_redux/dashboard/dashboardSlice";
import { tasksSlice } from "../app/modules/Application/_redux/tasks/tasksSlice";
import { timesheetSlice } from "../app/modules/Application/_redux/timesheet/timesheetSlice";
import { sprintsSlice } from "../app/modules/Application/_redux/sprint/sprintSlice";
import { usersSlice } from "../app/modules/Application/_redux/users/usersSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  boards: boardsSlice.reducer,
  teams: teamsSlice.reducer,
  invoices: invoicesSlice.reducer,
  teammembers: teammembersSlice.reducer,
  kanban: kanbanSlice.reducer,
  dashboard: dashboardSlice.reducer,
  tasks: tasksSlice.reducer,
  sprints: sprintsSlice.reducer,
  users: usersSlice.reducer,
  timesheet: timesheetSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
