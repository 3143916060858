/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = url => {
    return checkIsActive(location, url)
      ? " menu-item-active menu-item-open "
      : "";
  };

  const { currentState } = useSelector(
    state => ({ currentState: state.auth }),
    shallowEqual
  );

  let user_data = currentState.user;

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-bar2.svg")}
              />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Boards */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Boards</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/application/boards")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/application/boards">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Cupboard.svg")} />
            </span>
            <span className="menu-text">My Boards</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {user_data.role_details.role_level <= 4 && (
          <li
            className={`menu-item ${getMenuItemActive("/application/sprints")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/application/sprints">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Design/Horizontal.svg")}
                />
              </span>
              <span className="menu-text">Sprints</span>
            </NavLink>
          </li>
        )}

        {/* Teams */}
        {/* begin::section */}

        {/* end:: section */}
        {user_data.role_details.role_level <= 4 && (
          <>
            <li className="menu-section ">
              <h4 className="menu-text">Teams</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            <li
              className={`menu-item ${getMenuItemActive("/application/teams")}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/application/teams">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Group.svg"
                    )}
                  />
                </span>
                <span className="menu-text">My Teams</span>
              </NavLink>
            </li>
          </>
        )}

        <li
          className={`menu-item ${getMenuItemActive("/application/tasks")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/application/tasks">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Layout/Layout-arrange.svg"
                )}
              />
            </span>
            <span className="menu-text">My Tasks</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/application/tasks")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/application/invoices">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")} />
            </span>
            <span className="menu-text">Invoices</span>
          </NavLink>
        </li>
        {user_data.role_details.role_level <= 4 && (
          <li
            className={`menu-item ${getMenuItemActive("/application/users")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/application/users">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
              </span>
              <span className="menu-text">Users</span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}
        {/* Teams */}
        {/* begin::section */}
        {/* <li className="menu-section ">
          <h4 className="menu-text">Time Cards</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li> */}
        {/* end:: section */}

        {/* <li
          className={`menu-item ${getMenuItemActive(
            "/application/timesheets"
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/application/timesheets">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">TimeSheets</span>
          </NavLink>
        </li> */}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
