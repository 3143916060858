import {createSlice} from "@reduxjs/toolkit";

const initialTeammembersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  teammemberForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const teammembersSlice = createSlice({
  name: "teammembers",
  initialState: initialTeammembersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getteammemberById
    teammemberFetched: (state, action) => {
      state.actionsLoading = false;
      state.teammemberForEdit = action.payload.teammemberForEdit;
      state.error = null;
    },
    // findteammembers
    teammembersFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createteammember
    teammemberCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.teammember);
    },
    // updateteammember
    teammemberUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.teammember.id) {
          return action.payload.teammember;
        }
        return entity;
      });
    },
    // deleteteammember
    teammemberDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteteammembers
    teammembersDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
	// usersFetched
    usersFetched: (state, action) => {
	   const { entities } = action.payload;	
      state.error = null;
      state.actionsLoading = false;
      state.users = entities;
    }
  }
});
