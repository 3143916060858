import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
// import { getUserByToken } from './authCrud'

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  MicrosolutionLoaded: "[Load Token] Auth API"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  refreshToken: undefined
};

export const reducer = persistReducer(
  {
    storage,
    key: process.env.REACT_APP_LOCAL_STORAGE_VARIABLE,
    whitelist: ["user", "authToken", "refreshToken", "microsolution"]
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        return { ...state, ...action.payload };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      case actionTypes.MicrosolutionLoaded: {
        return {
          ...state,
          microsolution: { ...state.microsolution, ...action.payload }
        };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  login: data => ({
    type: actionTypes.Login,
    payload: data
  }),
  logout: () => ({ type: actionTypes.Logout }),
  fulfillMicrosolution: microsolution => ({
    type: actionTypes.MicrosolutionLoaded,
    payload: microsolution
  })
};

export function* saga() {}
