import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import { fetchDashboardStats } from "../../../app/modules/Application/_redux/dashboard/dashboardActions";

export function Dashboard() {
  const dispatch = useDispatch();
  const [loading_data_message, setLoadingDataMessage] = useState("");

  useEffect(() => {
    setLoadingDataMessage("Loading ....");
    dispatch(fetchDashboardStats());
    setLoadingDataMessage("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data } = useSelector(
    state => ({ data: state.dashboard.entities }),
    shallowEqual
  );
  return data ? (
    <>
      <div className="row m-0">
        <div className="col bg-light-warning px-6 py-8 rounded-xl mr-3 mb-7">
          <h2 className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
            {data.total_number_of_boards}
          </h2>
          <span className="text-warning font-weight-bold font-size-h6">
            Boards
          </span>
        </div>
        <div className="col bg-light-primary px-6 py-8 rounded-xl mr-3 mb-7">
          <h2 className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
            {data.total_number_of_tasks}
          </h2>
          <span className="text-primary font-weight-bold font-size-h6 mt-2">
            Tasks
          </span>
        </div>
        <div className="col bg-light-success px-6 py-8 rounded-xl mr-3 mb-7">
          <h2 className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
            {data.total_number_of_teams}
          </h2>
          <span className="text-success font-weight-bold font-size-h6 mt-2">
            Teams
          </span>
        </div>
      </div>
      <div className="row m-0">
        <div className="col-12 pl-0">
          <div className="card card-custom gutter-b example example-compact">
            <div className="card-header">
              <div className="card-title">
                <h3 className="card-label">Boards</h3>
              </div>
            </div>
            <div className="card-body">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Tasks</th>
                    <th>Hours of tasks</th>
                  </tr>
                </thead>
                <tbody>
                  {!data.hasOwnProperty("boards") ||
                  (data.hasOwnProperty("boards") && !data.boards.length) ? (
                    <tr>
                      <td colSpan="3">{loading_data_message}</td>
                    </tr>
                  ) : (
                    data.boards.map((board, i) => {
                      return (
                        <tr key={i}>
                          <td>{board.boardName}</td>
                          <td>
                            {"Total: " + board.total_tasks_in_board}

                            {board.total_tasks_in_board > 0
                              ? " (Backlog: " +
                                board.total_backlog_tasks +
                                ", To do: " +
                                board.total_todo_tasks +
                                ", Inprogress: " +
                                board.total_inprogress_tasks +
                                ", Review: " +
                                board.total_review_tasks +
                                ", Done: " +
                                board.total_done_tasks +
                                ")"
                              : ""}

                            {
                              <>
                                <br />
                                OverDue: {board.total_overdue_tasks}
                              </>
                            }
                          </td>
                          <td>{board.total_hours_of_tasks_in_board}</td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      {/* Teams table */}

      <div className="row m-0">
        <div className="col-12 pl-0">
          <div className="card card-custom gutter-b example example-compact">
            <div className="card-header">
              <div className="card-title">
                <h3 className="card-label">Teams</h3>
              </div>
            </div>
            <div className="card-body">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Team name</th>
                    <th>Board name</th>
                    <th>No. of users</th>
                  </tr>
                </thead>
                <tbody>
                  {!data.hasOwnProperty("teams") ||
                  (data.hasOwnProperty("teams") && !data.teams.length) ? (
                    <tr>
                      <td colSpan="3">{loading_data_message}</td>
                    </tr>
                  ) : (
                    data.hasOwnProperty("teams") &&
                    data.teams.length &&
                    data.teams.map((team, i) => {
                      return (
                        <tr key={i}>
                          <td>{team.teamName}</td>
                          <td>{team.Board.boardName}</td>
                          <td>{team.total_members}</td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    ""
  );
}
