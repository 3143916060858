import * as requestFromServer from "./dashboardCrud";
import { dashboardSlice, callTypes } from "./dashboardSlice";

const { actions } = dashboardSlice;

export const fetchDashboardStats = queryParams => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getStats(queryParams);
    if (response.data.status) {
      const { data } = response;
      dispatch(actions.dataFetched({ data }));
    }
    return response;
  } catch (error) {
    console.log(error);
    error.clientMessage = "Can't create account";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
    return error.response ? error.response : error;
  }
};
