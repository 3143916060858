import { createSlice } from "@reduxjs/toolkit";

const initialTimesheetState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  userTasks: [],
  timesheetForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const timesheetSlice = createSlice({
  name: "timesheet",
  initialState: initialTimesheetState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getTimesheetById
    timesheetFetched: (state, action) => {
      state.actionsLoading = false;
      state.timesheetForEdit = action.payload.timesheetForEdit;
      state.error = null;
    },
    // findTimesheet
    timesheetsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    userTasksFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.userTasks = entities;
      // state.totalCount = totalCount;
    },
    dashboardFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.dashboard = action.payload.dashboard;
    },
    // dashboardFetched
    // createTimesheet
    timesheetCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.timesheet);
    },
    // updateTimesheet
    timesheetUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.timesheet.id) {
          return action.payload.timesheet;
        }
        return entity;
      });
    },
    timesheetApproved: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.id) {
          return { ...entity, status: "approved" };
        }
        return entity;
      });
    },
    // deleteTimesheet
    timesheetDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteTimesheet
    timesheetsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // timesheetUpdateState
    timesheetStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
