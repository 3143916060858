import React, { Suspense, lazy } from "react";
import { ToastContainer } from "react-toastify";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import "react-toastify/dist/ReactToastify.css";

const ApplicationPages = lazy(() =>
  import("./modules/Application/pages/applicationPage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <ToastContainer />
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/application" component={ApplicationPages} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
