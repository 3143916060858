import { createSlice } from "@reduxjs/toolkit";

const initialSprintsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  sprintForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const sprintsSlice = createSlice({
  name: "sprints",
  initialState: initialSprintsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getsprintById
    sprintFetched: (state, action) => {
      state.actionsLoading = false;
      state.sprintForEdit = action.payload.sprintForEdit;
      state.error = null;
    },
    // findsprints
    sprintsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    //get sprint details
    sprintDetails: (state, action) => {
      const { sprintDetails } = action.payload;
      state.sprintDetails = sprintDetails;
    },
    // createsprint
    sprintCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.sprint);
    },
    // updatesprint
    sprintUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.sprint.id) {
          return action.payload.sprint;
        }
        return entity;
      });
      //console.log(state.entities);
    },
    // deletesprint
    sprintDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deletesprints
    sprintsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // sprintsUpdateState
    sprintsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
