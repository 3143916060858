import Swal from "sweetalert2";
import * as auth from "../app/modules/Auth/_redux/authRedux";

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );
  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (
        error.response.status === 400 ||
        error.response.status === 500 ||
        error.response.status === 404
      ) {
        return {
          ...error.response
        };
      } else if (error.response.status === 403) {
        Swal.fire({
          icon: "error",
          title: "Session expired",
          html: `<strong>Your session has expired. Login again</strong>`
        }).then(() => {
          window.location.href = "/logout";
        });
      } else if (error.response.status === 401) {
        store.dispatch(auth.actions.logout());
        throw error;
      }
      return error;
    }
  );
}
